/*VIDEO SCRIPT*/
function onYouTubeIframeAPIReady(video_player_id) {
    console.log(video_player_id);
    const playerParent = document.getElementById(video_player_id);
    const playerContainer = playerParent.querySelector('.js-video-home__player');
    
    playerParent.querySelector('.js-video-home__overlay').classList.add('hidden');

    const player = new YT.Player(playerContainer, {
        height: '100%',
        width: '100%',
        videoId: playerParent,
        playerVars: {
            'autoplay': 1,
            'playsinline': 1,
            'mute': 1,
            'enablejsapi': 1,
            'rel': 0,
            'loop': 0,
            'playlist': playerParent.getAttribute('data-video-id'),
            'modestbranding': 1,
            'autohide': 1,
            'showinfo': 0,
        },
        events: {
            'onReady': onPlayerReady
        }
    });
}

function onPlayerReady(event) {
    event.target.playVideo();
}

const playButton = document.querySelectorAll('.js-video-home__play-button');

playButton.forEach((playButton) => {
    playButton.addEventListener('click', (event) => {
        const videoID = event.target.getAttribute('data-video');

        setTimeout(function () {
            onYouTubeIframeAPIReady(videoID);
        }, 250);
    });
});