window.onscroll = function () { scrollHeader() };

var navigation = document.getElementById("main-nav");
var navigationOffset = navigation.offsetTop;

const scrollHeader = () => {
	if (window.pageYOffset > navigationOffset) {
		navigation.classList.add("scrolled");
	} else {
		navigation.classList.remove("scrolled");
	}
}